import * as React from 'react';
import { AppLayout } from '../components/Layouts/AppLayout';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useDebugAttrs } from '@benefeature/shared/ui';
import { DisplaySmall, HeadingMedium, ParagraphMedium } from 'baseui/typography';
import { ContactEnum } from '@benefeature/shared-common';
import { CommonMetaHead } from '../components/CommonMetaHead';
import { useRouter } from 'next/router';

const metaTitle = '404 Not Found | Benefeature';
const metaDescription = 'The page you were looking for was not found.';

export default function NotFoundPage() {
  const [section] = useDebugAttrs('404-not-found');
  const [, /*css*/ theme] = useStyletron();

  const router = useRouter();
  const isWindowDefined = typeof window !== 'undefined';

  return (
    <>
      <CommonMetaHead title={metaTitle} description={metaDescription} />

      <Block {...section('root')}>
        <Block
          height={'100vh'}
          width={'100%'}
          paddingTop={'128px'}
          paddingLeft={'80px'}
          paddingRight={'80px'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <DisplaySmall>
            Not Found <span color={theme.colors.contentTertiary}>404</span>
          </DisplaySmall>
          <HeadingMedium marginBottom={0} marginTop={'72px'}>
            Expecting to see content here?
          </HeadingMedium>
          <ParagraphMedium>
            Email our support team at:{' '}
            <a /* Special onclick handler to open the mailto in a new tab/window if possible */
              onClick={(event) => {
                event.preventDefault();
                window?.open(
                  `mailto:${ContactEnum.HELLO_EMAIL}?subject=Unexpected 404 at ${encodeURIComponent(router?.asPath)}`,
                  'mail'
                );
              }}
              href={`mailto:${ContactEnum.HELLO_EMAIL}?subject=Unexpected 404 at ${encodeURIComponent(router?.asPath)}`}
            >
              {ContactEnum.HELLO_EMAIL}
            </a>
            {isWindowDefined && (window as any)?.HubSpotConversations?.widget?.status()?.loaded ? (
              <>
                {' '}
                or{' '}
                <a /* Special onclick handler to open the mailto in a new tab/window if possible */
                  onClick={() => {
                    // Explicitly cast window to any in order to force the otherwise-unknown HubSpotConversations to pass linting
                    const anyWindow = (window as any) || {};
                    anyWindow?.HubSpotConversations?.widget?.open();
                  }}
                  href={''}
                >
                  chat with us
                </a>
              </>
            ) : null}
          </ParagraphMedium>
        </Block>
      </Block>
    </>
  );
}

const SupportPageLayout = ({ children, data, ...props }) => {
  return (
    <AppLayout noTopBottomPad {...props}>
      {children}
    </AppLayout>
  );
};

NotFoundPage.Layout = SupportPageLayout;
